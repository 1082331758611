import { Controller } from "stimulus"

import Stimulus from "../../common/utils/stimulus"
import { scrollIntoView } from "../../common/utils/scroll"

class RegionPage extends Controller {
  static targets = [`map`, `description`]

  connect() {
    if (
      this.hasDescriptionTarget &&
      window.location.hash == `#${this.descriptionTarget.id}`
    )
      this.goToDescription()
  }

  mapTargetConnected() {
    window.setTimeout(() => {
      this.mapController.addReceiveHandler(this.mapReceive)
    }, 10)
  }

  mapReceive = (message) => {
    switch (message.method) {
      case `loaded`: {
        if (window.location.hash == `#${this.mapTarget.id}`)
          this.enterMapFullscreen()
        break
      }

      case `setQuery`: {
        this.mapQueryWasSet(message.data)
        break
      }
    }
  }

  enterMapFullscreen = () => {
    return new Promise((resolve, reject) => {
      scrollIntoView(this.mapTarget, {
        behavior: `smooth`,
        block: `center`,
      }).then(() => {
        this?.mapController.setFullscreen(true).then(resolve)
      })
    })
  }

  goToMap = (e) => {
    if (!this.hasMapTarget) return
    if (e) e.preventDefault()

    let query = ``
    if (e?.params?.query) query = e.params.query
    else if (e?.currentTarget?.dataset?.categoryButtonId)
      query = `category=${e.currentTarget.dataset.categoryButtonId}`

    this.enterMapFullscreen().then(() =>
      this.mapController.setQueryWithDefault(query)
    )
  }

  mapQueryWasSet = (query) => {
    const urlTypes = this.mapTarget.dataset.urlTypes.split(`,`)
    const params = {}

    query.forEach((q) => {
      if (urlTypes.includes(q.type)) {
        params[q.type] = params[q.type] || []
        params[q.type].push(q.value)
      }
    })

    let url = Object.entries(params)
      .map(([k, v]) => `${k}=${v.join(`,`)}`)
      .join(`&`)

    if (url && this.mapTarget.id) url += `#${this.mapTarget.id}`

    window.history.replaceState(null, null, `?${url}`)
  }

  goToDescription = () => {
    // takes to description instantly to avoid lazy loading of above objects whose presence alters page's height and puts top of the description out of viewport
    if (this.hasDescriptionTarget)
      scrollIntoView(this.descriptionTarget, { behavior: `instant` })
  }

  get mapController() {
    if (!this.hasMapTarget) return null

    return this.application.getControllerForElementAndIdentifier(
      this.mapTarget,
      `map-new`
    )
  }
}

Stimulus.register(`region-page`, RegionPage)
